import React from "react"
import { ServicePageLayout, layoutColorTypes } from "../../../layouts/service-page/service-page.layout"
import { getCanonicalUrl } from "../../../utils/getCanonicalUrl"

import { testimonials } from "./testimonials"
import { getStaticImgUrl } from "../../../utils/getStaticImgUrl"

const content = {
  title: "Review Writing Services",
  whatWeOffer: {
    leftPart: "Trust is a big problem in e-commerce. Even if you have quality product photos and descriptions, it's not enough. It would help if you had reviews, positive, neutral, and negative. They will demystify any insecurities, providing customers with a sense of security.",
    rightPart: "Reviews show that actual people used your product or service. Writing them is not an easy task, but our team of writing experts with years of experience can certainly help you with this."
  },
  processText: "We will assess your offer, test it, and provide real reviews future customers will appreciate. Contact us today for a quote.",
  testimonials: testimonials
}

const meta = {
  title: "Review Writing Services",
  description: "Trust is a big problem in e-commerce. Even if you have quality product photos and descriptions, it's not enough. It would help if you had reviews, positive, neutral, and negative. They will demystify any insecurities, providing customers with a sense of security."
}

const og = {
  image: getStaticImgUrl("img_contnet_writing_full.jpg")
}

const pageSlug = "content-services/review-writing";

export default () => (

  <ServicePageLayout 

    meta={meta}
    og={og}
    layoutColorType={layoutColorTypes.YELLOW}
    rightHeroImage={"/img_contnet_writing_full.jpg"}
    headerIcon={"/ico_content_writing_2@2x.png"}
    content={content}
    canonicalUrl={getCanonicalUrl(pageSlug)}

  />

)